<template>
  <div>{{ msg }}</div>
</template>

<script>
export default {
  props: {
    tmplId: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    let msg;
    if (!this.tmplId) {
      msg = 'Block Name not specified!';
    } else {
      msg = `Block [ ${this.tmplId} ] not found!`;
    }
    return {
      msg,
    };
  },
};
</script>

<style lang="scss">

</style>
