<template>
  <div class="comp-banner">
    <img class="img" src="https://hrss-tes.oss-cn-beijing.aliyuncs.com/hrss/image/2021/04/1618642890763.png" alt="">
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss">
.comp-banner {
  .img {
    display: block;
    width: 100%;
  }
}
</style>
