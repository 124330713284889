<template>
    <div class="footer-temp"
      v-if="data.pageEnd"
      :style="{'background': data.pageEnd.subjectColor}">
      <div class="footer-content">
        <div class="left-box">
          <div class="footer-l" v-if="data.pageEnd.logo || data.pageEnd.leftSelectSetting">
            <img :src="data.pageEnd.logo" alt="" v-if="data.pageEnd.logo">
            <div
              class="friend-link"
              v-if="data.pageEnd.leftSelectSetting"
              :style="{'margin-top':data.pageEnd.logo ? '24px': '0'}"
              :class="data.pageEnd.subjectColor == '#333333' ? '' : 'black'">
              <!-- 友情链接 -->
              <span
                class="footer-link-title"
                v-if="data.pageEnd.selectTitle"
              >
                {{data.pageEnd.selectTitle}}
              </span>
              <el-select
                v-model="jumpId"
                placeholder="请选择"
                size="small"
                @change="getOpenMode"
              >
                <el-option
                  v-for="item in data.pageEnd.selectList"
                  :key="item.id"
                  :label="item.linkName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- 前往 -->
              <span
                class="friend-button"
                @click="openLink"
                v-if="data.pageEnd.buttonText"
                :style="{'background': data.pageEnd.buttonColor}"
              >
                {{data.pageEnd.buttonText}}
              </span>
            </div>
          </div>
          <div class="footer-c" :class="data.pageEnd.subjectColor == '#333333' ? '' : 'wordColor'">
            <!-- 中间文字内容 -->
            <div class="footer-center-link"
              v-if="data.pageEnd.middleWordLinkList.length && data.pageEnd.middleWordLink">
              <span
                v-for="(item, index) in data.pageEnd.middleWordLinkList" :key="index"
                @click="openWordLink(item)"
              >
                {{item.linkName}}
              </span>
            </div>
            <!-- 中间文字链接 -->
            <div v-if="data.pageEnd.middleTextContent" class="center-text-link">
              <p v-for="(item, index) in data.pageEnd.middleTextContentList" :key="index">
                <span class="text-justify">{{item.titleName}}</span>
                <span>:</span>
                <span class="text-info">{{item.titleContent}}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 右部媒体信息 -->
        <div class="footer-r">
          <div class="code-flex">
            <div class="qr-code"
              :class="data.pageEnd.subjectColor == '#333333' ? '' : 'codeColor'"
              v-for="(item, index) in data.pageEnd.rightSocialMediaList" :key="index">
              <img :src="item.qrCode" alt="" v-if="item.qrCode">
              <span v-if="item.titleName">{{item.titleName}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 备案 -->
      <div
        class="footer-bottom"
        :style="{'background': data.pageEnd.bottomColor}"
        v-if="data.pageEnd.copyrightSwitch"
      >
        <span v-html="data.pageEnd.copyright"></span>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    tempData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      jumpId: '',
      jumpUrl: '',
      openMode: '',
      data: this.tempData
    }
  },
  mounted() {
    if (this.data.pageEnd == null) return;
    this.data.pageEnd.selectList.map((item,index)=> {
      return item.id = index++;
    })
  },
  methods: {
    // 前往下拉链接
    openLink() {
      if (!this.jumpUrl) return;
      if (this.jumpUrl.includes('http')) {
        this.openMode == 1 ? window.location.href = this.jumpUrl : window.open(this.jumpUrl,'_blank');
      } else {
        if (this.openMode == 1) {
          this.$router.push({
            path: this.jumpUrl
          })
        } else {
          const { href } = this.$router.resolve({
            path: this.jumpUrl
          });
          window.open(href, '_brank');
        }
      }
    },
    // 前往文字链接
    openWordLink(item) {
      if (!item.jumpLink) return;
      if (item.jumpLink.includes('http')) {
        item.openMode == 1 ? window.location.href = item.jumpLink : window.open(item.jumpLink,'_blank');
      } else {
        if (item.openMode == 1) {
          this.$router.push({
            path: item.jumpLink
          })
        } else {
           const { href } = this.$router.resolve({
              path: item.jumpLink
          });
          window.open(href, '_brank');
        }
      }
    },
    getOpenMode(id) {
      this.data.pageEnd.selectList.map(item => {
        if (item.id == id) {
          this.jumpUrl = item.jumpLink;
          this.openMode = item.openMode;
        }
      })
    },
    filterMsg(data) {
      if (data != null) {
        return data.replace(/\s/g,"&nbsp;");
      }
    }
  }
}
</script>
<style lang="scss" scoped>
   .footer-temp {
    width: 100%;
    .footer-content {
      font-size: 14px;
      color: #808080;
      width: 1366px;
      padding: 24px 16px;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left-box {
        display: flex;
      }
      .footer-l {
        margin-right: 160px;
        text-align: left;
        font-size: 14px;
        color: #979797;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          max-width: 320px;
        }
        .friend-link {
          .footer-link-title {
            font-size: 14px;
            color: rgba(166,169,175,0.7)
          }
          >>>.el-select {
            width: 160px;
            height: 32px;
            margin: 0 8px 0 8px;
            .el-input__inner {
              height: 30px;
              background: transparent;
              border: 1px solid #606266;
            }
            .el-input--small .el-input__icon{
              line-height: 32px;
            }
            .el-input__inner::placeholder {
              color: #606266;
            }
            .el-input__prefix, .el-input__suffix {
              color: #606266;
            }
          }
          .friend-button {
            display: inline-block;
            color: #fff;
            font-size: 12px;
            width: 60px;
            height: 32px;
            background: linear-gradient(141deg, #5493FF 0%, #2978FF 100%);
            border-radius: 3px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
          }
        }
        .black {
          .footer-link-title {
            color: #606266;
          }
          >>>.el-select {
            .el-input__inner {
              height: 32px;
              background: #FFFFFF;
              border: 1px solid #D6DEEA;
            }
            .el-input__inner::placeholder {
              color: #C0C4CC;
            }
          }
        }
      }
      .footer-c {
        font-size: 14px;
        .footer-center-link {
          font-size: 14px;
          color: rgba(166,169,175,0.7);
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          span {
            display: inline-block;
            margin-bottom: 4px;
            width: 360px;
            overflow:hidden;
          }
        }
        .center-text-link {
          font-size: 14px;
        }
        p {
          .text-justify {
            text-align-last:justify;
            text-align:justify;
            text-justify:distribute-all-lines; // 兼容ie浏览器
            width: 88px;
            display: inline-block;
            margin-right: 3px;
          }
          .text-info {
            margin-left: 6px;
            width: 360px;
            overflow: hidden;
            display: inline-block;
          }
          margin-bottom: 4px;
          color: rgba(166,169,175,0.7);
          display: flex;
          align-items: center;
        }
      }
      .wordColor {
        .footer-center-link {
          color: #606266;
        }
        p {
          color: #606266;
        }
      }
      .footer-r {
        text-align: right;
        // padding-top: 32px;
        box-sizing: border-box;

        .code-flex {
          display: flex;
          align-items: center;
          margin-right: auto;
          float:right
        }
        .qr-code {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 24px;
          img {
            width: 104px;
            height: 104px;
            margin-bottom: 8px;
          }
          span {
            font-size: 12px;
            color: #979797;
          }
        }
        .codeColor {
          span {
            font-size: 12px;
            color: #909399;
          }
        }
      }
    }
    .footer-bottom {
      text-align: center;
      padding: 8px 0;
      box-sizing: border-box;
      span {
        color: #909399;
        font-size: 14px;
      }
    }
  }
</style>
